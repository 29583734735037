import axios from 'axios'
import store from './store'

export default class ApiClient {
  baseUrl: string
  mainUrl: string

  constructor(url: string, main: string) {
    this.baseUrl = url + '/api'
    this.mainUrl = main + '/api'
  }

  login(authData: any) {
    return axios.post(`${this.mainUrl}/login`, authData)
  }

  acceptTOS() {
    return axios.post(`${this.baseUrl}/tos`, {}, { headers: this.getHeaders() })
  }

  // getShops(params: any) {
  //   return axios.get(`${this.baseUrl}/shops`, { params, headers: this.getHeaders() })
  // }

  getFacebookAuthURL() {
    return axios.get(`${this.baseUrl}/fb/init`, { headers: this.getHeaders() })
  }

  facebookCallback(data) {
    return axios.post(`${this.baseUrl}/fb/callback`, data, { headers: this.getHeaders() })
  }

  getBusinesses(): Promise<{ data: yakkyo.IFacebookAccount[] }> {
    return axios.get(`${this.baseUrl}/fb/businesses`, { headers: this.getHeaders() })
  }

  updateBusiness(adAccountId: string, data: any): Promise<{ data: any }> {
    return axios.put(`${this.baseUrl}/fb/businesses/${adAccountId}`, data, { headers: this.getHeaders() })
  }

  getAdImages(adAccountId: string) {
    return axios.get(`${this.baseUrl}/fb/${adAccountId}/ad-images`, { headers: this.getHeaders() })
  }

  // currently not used
  getTemplate(id: string, type = 'campaign') {
    return axios.get(`${this.baseUrl}/templates/${type}/${id}`, { headers: this.getHeaders() })
  }

  getTemplates(params: any, type = 'campaign') {
    return axios.get(`${this.baseUrl}/templates/${type}`, { headers: this.getHeaders(), params })
  }

  removeTemplate(id: string, type = 'campaign') {
    return axios.delete(`${this.baseUrl}/templates/${type}/${id}`, { headers: this.getHeaders() })
  }

  patchTemplate(id: string, data: any, type = 'campaign') {
    return axios.put(`${this.baseUrl}/templates/${type}/${id}`, data, { headers: this.getHeaders() })
  }

  postTemplate(data: any, type = 'campaign') {
    return axios.post(`${this.baseUrl}/templates/${type}`, data, { headers: this.getHeaders() })
  }

  getCustomers(params: any) {
    return axios.get(`${this.baseUrl}/customers`, { headers: this.getHeaders(), params })
  }

  getUser() {
    return axios.get(`${this.baseUrl}/me`, { headers: this.getHeaders() })
  }

  getShops(params?: any) {
    return axios.get(`${this.baseUrl}/shops`, { headers: this.getHeaders(), params })
  }

  getShopProducts(id: string, productIds: string[]) {
    return axios.get(`${this.baseUrl}/shops/${id}/products?products=${productIds.join(',')}`, {
      headers: this.getHeaders()
    })
  }

  disconnectFacebookAccount(id: string) {
    return axios.delete(`${this.baseUrl}/fb/accounts/${id}`, { headers: this.getHeaders() })
  }

  fetchFacebookAccount(id: string) {
    return axios.put(`${this.baseUrl}/fb/accounts/${id}`, {}, { headers: this.getHeaders() })
  }

  getProducts(params: any) {
    return axios.get(`${this.baseUrl}/products`, { headers: this.getHeaders(), params })
  }

  getProduct(id: string) {
    return axios.get(`${this.baseUrl}/products/${id}`, { headers: this.getHeaders() })
  }

  publishCampaign(data: any) {
    return axios.post(`${this.baseUrl}/fb/ads`, data, { headers: this.getHeaders(), timeout: 5 * 60 * 1000 })
  }

  getInterests(adAccountId: string, interest: string, interestLocale: string) {
    return axios.get(`${this.baseUrl}/fb/interests/${adAccountId}`, {
      headers: this.getHeaders(),
      params: { interest, interestLocale }
    })
  }

  getCampaigns(adAccountId: string, params: any) {
    return axios.get(`${this.baseUrl}/fb/campaigns/${adAccountId}`, { headers: this.getHeaders(), params })
  }
  getAdCampaignAdsets(campaignId: string, adAccountId) {
    return axios.get(`${this.baseUrl}/fb/insights/get-campaign-adsets/${campaignId}/${adAccountId}`, {
      headers: this.getHeaders()
    })
  }
  copyCampaignToAnotherAccount(data: any) {
    return axios.post(`${this.baseUrl}/fb/campaigns/copy-campaign-data`, data, { headers: this.getHeaders() })
  }

  updateCampaignInsights(adAccountId: string) {
    return axios.put(`${this.baseUrl}/fb/insights/${adAccountId}/campaigns`, {}, { headers: this.getHeaders() })
  }

  updateAdSetInsights(adAccountId: string, campaignId: string) {
    return axios.put(
      `${this.baseUrl}/fb/insights/${adAccountId}/${campaignId}/adsets`,
      {},
      { headers: this.getHeaders() }
    )
  }

  updateAdInsights(adAccountId: string, adSetId: string) {
    return axios.put(`${this.baseUrl}/fb/insights/${adAccountId}/${adSetId}/ads`, {}, { headers: this.getHeaders() })
  }

  updateCampaign(id: string, payload: any) {
    return axios.put(`${this.baseUrl}/fb/campaigns/${id}`, payload, { headers: this.getHeaders() })
  }

  getAdSet(id: string) {
    return axios.get(`${this.baseUrl}/fb/adsets/${id}`, { headers: this.getHeaders() })
  }

  updateAdSet(id: string, payload: any) {
    return axios.put(`${this.baseUrl}/fb/adsets/${id}`, payload, { headers: this.getHeaders() })
  }

  getAd(id: string) {
    return axios.get(`${this.baseUrl}/fb/ads/${id}`, { headers: this.getHeaders() })
  }

  updateAd(id: string, payload: any) {
    return axios.put(`${this.baseUrl}/fb/ads/${id}`, payload, { headers: this.getHeaders() })
  }

  copyCampaign(payload: any) {
    return axios.post(`${this.baseUrl}/fb/campaigns/${payload.id}/copy`, payload, { headers: this.getHeaders() })
  }

  copyAdSet(id: string) {
    return axios.post(`${this.baseUrl}/fb/adsets/${id}/copy`, {}, { headers: this.getHeaders() })
  }

  copyAd(id: string) {
    return axios.post(`${this.baseUrl}/fb/ads/${id}/copy`, {}, { headers: this.getHeaders() })
  }

  getAdSets(campaignId: string, params: any) {
    return axios.get(`${this.baseUrl}/fb/adsets`, { headers: this.getHeaders(), params: { ...params, campaignId } })
  }

  getAds(adSetId: string, params: any) {
    return axios.get(`${this.baseUrl}/fb/ads`, {
      headers: this.getHeaders(),
      params: { ...params, adSetId }
    })
  }

  getFbAdImages(adAccountId: string, params: any) {
    return axios.get(`${this.baseUrl}/fb/adimages/${adAccountId}`, { headers: this.getHeaders(), params })
  }

  getFbAdVideos(adAccountId: string, params: any) {
    return axios.get(`${this.baseUrl}/fb/advideos/${adAccountId}`, { headers: this.getHeaders(), params })
  }

  getCampaign(adAccountId: string, id: string) {
    return axios.get(`${this.baseUrl}/fb/campaigns/${adAccountId}/${id}`, { headers: this.getHeaders() })
  }

  getProductInsights(params: any) {
    return axios.get(`${this.baseUrl}/fb/insights/products`, { headers: this.getHeaders(), params })
  }

  getOverviewInsights(params: any) {
    return axios.get(`${this.baseUrl}/fb/insights/overview`, { headers: this.getHeaders(), params })
  }

  getReachEstimates(adAccountId: string, targeting: any) {
    return axios.get(`${this.baseUrl}/fb/reach-estimates/${adAccountId}`, {
      headers: this.getHeaders(),
      params: targeting
    })
  }

  getLocales(adAccountId: string, locale: string) {
    return axios.get(`${this.baseUrl}/fb/locales/${adAccountId}`, {
      headers: this.getHeaders(),
      params: { locale }
    })
  }

  updateProductPicture(productId: string, src: string) {
    return axios.put(`${this.baseUrl}/products/${productId}/pictures`, { src }, { headers: this.getHeaders() })
  }

  updateProductVideo(productId: string, src: string) {
    return axios.put(`${this.baseUrl}/products/${productId}/videos`, { src }, { headers: this.getHeaders() })
  }

  refresh(data: any = {}) {
    // prettier-ignore
    const headers = { 'x-refresh-token': store.state.refreshToken, 'x-access-token': store.state.token }
    return axios.post(this.mainUrl + '/refresh-token', data, { headers })
  }

  // --------------------------------------
  // ------------ Automations -------------
  // --------------------------------------
  // TODO: define standard params for every list endpoint
  listAutomations(params: any, adAccountId: string) {
    return axios.get(`${this.baseUrl}/automations`, { headers: this.getHeaders(), params: { ...params, adAccountId } })
  }

  getAutomation(id: string) {
    return axios.get(`${this.baseUrl}/automations/${id}`, { headers: this.getHeaders() })
  }

  getPublicAutomation(id: string) {
    return axios.get(`${this.baseUrl}/automations/public/${id}`, { headers: this.getHeaders() })
  }

  removeAutomation(id: string) {
    return axios.delete(`${this.baseUrl}/automations/${id}`, { headers: this.getHeaders() })
  }

  postAutomation(data: Partial<yakkyo.IFacebookAutomation>) {
    return axios.post(`${this.baseUrl}/automations`, data, { headers: this.getHeaders() })
  }

  updateAutomation(id: string, data: Partial<yakkyo.IFacebookAutomation>) {
    return axios.put(`${this.baseUrl}/automations/${id}`, data, { headers: this.getHeaders() })
  }

  duplicateAutomation(automationId: string, adAccountId: string) {
    return axios.post(`${this.baseUrl}/automations/copy`, { automationId, adAccountId }, { headers: this.getHeaders() })
  }

  // TODO: not really automations
  listFacebookEntities(type: 'ad' | 'adset' | 'campaign', params: any) {
    return axios.get(`${this.baseUrl}/fb/list/${type}`, { headers: this.getHeaders(), params })
  }

  private getHeaders() {
    const token = store.state.token
    return { 'x-access-token': token }
  }
}
