// https://developers.facebook.com/docs/marketing-api/reference/ad-campaign-group#attribution_spec
export const attributions = [
  {
    label: '1 Day Click',
    value: [{ event_type: 'CLICK_THROUGH', window_days: 1 }]
  },
  { label: '7 Days Click', value: [{ event_type: 'CLICK_THROUGH', window_days: 7 }] },
  {
    label: '1 Day Click or View',
    value: [
      { event_type: 'VIEW_THROUGH', window_days: 1 },
      { event_type: 'CLICK_THROUGH', window_days: 1 }
    ]
  },
  {
    label: '7 Days Click or 1 Day View',
    value: [
      { event_type: 'CLICK_THROUGH', window_days: 7 },
      { event_type: 'VIEW_THROUGH', window_days: 1 }
    ]
  }
]

export const buyingTypes = [
  { label: 'Auction (default)', value: 'AUCTION' },
  { label: 'Reserved', value: 'RESERVED' }
]

export const bidStrategies = [
  { label: 'Lowest Cost (automatic bidding)', value: 'LOWEST_COST_WITHOUT_CAP' },
  { label: 'Lowest Cost with Bid Cap (manual maximum-cost bidding)', value: 'LOWEST_COST_WITH_BID_CAP' },
  { label: 'Cost Cap', value: 'COST_CAP' }
]

// https://developers.facebook.com/docs/marketing-api/bidding/overview/billing-events/
export const billingEvents = {
  APP_INSTALLS: ['IMPRESSIONS'],
  AD_RECALL_LIFT: ['IMPRESSIONS'],
  ENGAGED_USERS: ['IMPRESSIONS'],
  EVENT_RESPONSES: ['IMPRESSIONS'],
  IMPRESSIONS: ['IMPRESSIONS'],
  LEAD_GENERATION: ['IMPRESSIONS'],
  LINK_CLICKS: ['LINK_CLICKS', 'IMPRESSIONS'],
  OFFSITE_CONVERSIONS: ['IMPRESSIONS'],
  PAGE_LIKES: ['IMPRESSIONS'],
  POST_ENGAGEMENT: ['IMPRESSIONS'],
  REACH: ['IMPRESSIONS'],
  REPLIES: ['IMPRESSIONS'],
  SOCIAL_IMPRESSIONS: ['IMPRESSIONS'],
  THRUPLAY: ['IMPRESSIONS', 'THRUPLAY'],
  TWO_SECOND_CONTINUOUS_VIDEO_VIEWS: ['IMPRESSIONS', 'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS', 'VIDEO_VIEWS'],
  VALUE: ['IMPRESSIONS'],
  LANDING_PAGE_VIEWS: ['IMPRESSIONS']
}

export const objectives = [
  {
    label: 'Traffic',
    value: 'OUTCOME_TRAFFIC',
    description: 'Send people to a destination like your website, app or Shop.',
    conversionEvents: [],
    optimizationGoals: [
      { label: 'Landing Page Views', value: 'LANDING_PAGE_VIEWS' },
      { label: 'Link Clicks', value: 'LINK_CLICKS' },
      { label: 'Impressions', value: 'IMPRESSIONS' },
      { label: 'Daily Unique Reach', value: 'REACH' }
    ],
    legacy: 'Traffic'
  },
  {
    label: 'Engagement',
    value: 'OUTCOME_ENGAGEMENT',
    description:
      'Find people likely to interact with your business online, and take actions like starting a conversation or commenting on posts.',
    conversionEvents: [
      { label: 'AddToWishlist', value: 'ADD_TO_WISHLIST' },
      { label: 'Contact', value: 'CONTACT' },
      { label: 'CustomizeProduct', value: 'CUSTOMIZE_PRODUCT' },
      { label: 'Donate', value: 'DONATE' },
      { label: 'FindLocation', value: 'FIND_LOCATION' },
      { label: 'Schedule', value: 'SCHEDULE' },
      { label: 'Search', value: 'SEARCH' },
      { label: 'StartTrial', value: 'START_TRIAL' },
      { label: 'SubmitApplication', value: 'SUBMIT_APPLICATION' },
      { label: 'Subscribe', value: 'SUBSCRIBE' },
      { label: 'ViewContent', value: 'CONTENT_VIEW' }
    ],
    optimizationGoals: [
      { label: 'Conversions (offsite)', value: 'OFFSITE_CONVERSIONS' },
      { label: 'Conversions (onsite)', value: 'ONSITE_CONVERSIONS' },
      { label: 'Landing Page Views', value: 'LANDING_PAGE_VIEWS' },
      { label: 'Link Clicks', value: 'LINK_CLICKS' },
      { label: 'Impressions', value: 'IMPRESSIONS' },
      { label: ' Daily Unique Reach', value: 'REACH' }
    ],
    legacy: 'Conversions'
  },
  {
    label: 'Leads',
    value: 'OUTCOME_LEADS',
    description: 'Find people interested in your business who are likely to share their contact information.',
    conversionEvents: [
      { label: 'Lead', value: 'LEAD' },
      { label: 'CompleteRegistration', value: 'COMPLETE_REGISTRATION' },
      { label: 'Contact', value: 'CONTACT' },
      { label: 'FindLocation', value: 'FIND_LOCATION' },
      { label: 'Schedule', value: 'SCHEDULE' },
      { label: 'StartTrial', value: 'START_TRIAL' },
      { label: 'SubmitApplication', value: 'SUBMIT_APPLICATION' },
      { label: 'Subscribe', value: 'SUBSCRIBE' }
    ],
    optimizationGoals: [
      { label: 'Conversions (offsite)', value: 'OFFSITE_CONVERSIONS' },
      { label: 'Conversions (onsite)', value: 'ONSITE_CONVERSIONS' },
      { label: 'Landing Page Views', value: 'LANDING_PAGE_VIEWS' },
      { label: 'Link Clicks', value: 'LINK_CLICKS' },
      { label: 'Impressions', value: 'IMPRESSIONS' },
      { label: 'Daily Unique Reach', value: 'REACH' }
    ],
    legacy: 'Conversions'
  },
  {
    label: 'Sales',
    value: 'OUTCOME_SALES',
    description: 'Find people likely to make purchases or take other important actions online or in store..',
    conversionEvents: [
      { label: 'Purchase', value: 'PURCHASE' },
      { label: 'InitiateCheckout', value: 'INITIATED_CHECKOUT' },
      { label: 'AddPaymentInfo', value: 'ADD_PAYMENT_INFO' },
      { label: 'AddToCart', value: 'ADD_TO_CART' },
      { label: 'CompleteRegistration', value: 'COMPLETE_REGISTRATION' },
      { label: 'Donate', value: 'DONATE' },
      { label: 'StartTrial', value: 'START_TRIAL' },
      { label: 'Subscribe', value: 'SUBSCRIBE' },
      { label: 'ViewContent', value: 'CONTENT_VIEW' }
    ],
    optimizationGoals: [
      { label: 'Conversions', value: 'OFFSITE_CONVERSIONS' },
      { label: 'Value', value: 'VALUE' },
      { label: 'Landing Page Views', value: 'LANDING_PAGE_VIEWS' },
      { label: 'Link Clicks', value: 'LINK_CLICKS' },
      { label: 'Impressions', value: 'IMPRESSIONS' },
      { label: 'Daily Unique Reach', value: 'REACH' }
    ],
    legacy: 'Conversions'
  }
]

export const defaultAdCreative: Partial<yakkyo.IFacebookAdTemplateAdCreative> = {
  name: '', // just the template name
  status: 'ACTIVE',
  link: '',
  title: '',
  description: '',
  body: '',
  urlTags: {
    utm_medium: '{{placement}}',
    utm_campaign: '{{campaing.name}}',
    utm_source: 'cpc',
    utm_term: '{{ad.id}}',
    utm_content: '{{ad.name}}'
  },
  callToAction: ''
}

export const defaultAdSet: Partial<yakkyo.IFacebookAdTemplateAdSet> = {
  name: '', // just the template name
  status: 'ACTIVE',
  optimization_goal: '',
  billing_event: '', // https://developers.facebook.com/docs/marketing-api/bidding/overview/billing-events
  bid_strategy: 'LOWEST_COST_WITHOUT_CAP', // LOWEST_COST_WITH_BID_CAP - https://developers.facebook.com/docs/marketing-api/bidding/overview#opt
  bid_amount: '2',
  budget: 10,
  spend_cap: 0,
  budgetWindow: 'DAILY',
  attribution_spec: [], // conversion window
  conversion_event: '',
  instagramAccount: '',
  pixel: '',
  numberOfVariations: 1,

  targeting: {
    geo_locations: { countries: ['IT'] },
    interests: [],
    locales: [],
    age_min: 13,
    age_max: 65,
    genders: [1, 2],
    placements: {
      manual: false,
      device_platforms: ['desktop', 'mobile'],
      publisher_platforms: ['facebook', 'instagram', 'audience_network', 'messenger'],
      facebook_positions: [
        'feed',
        'right_hand_column',
        'marketplace',
        'video_feeds',
        'story',
        'search',
        'instream_video',
        'facebook_reels'
      ],
      instagram_positions: ['stream', 'story', 'shop', 'explore', 'explore_home', 'reels', 'profile_feed'],
      audience_network_positions: ['classic', 'rewarded_video'],
      messenger_positions: ['story', 'sponsored_messages', 'messenger_home']
    }
  }
}

export const defaultCampaign: Partial<yakkyo.IFacebookAdTemplateCampaign> = {
  objective: '', // 'CONVERSIONS', // LINK_CLICKS - https://developers.facebook.com/docs/marketing-api/bidding/overview#opt
  status: 'ACTIVE',
  conversion_domain: '',
  labels: [],
  cbo: true,
  buying_type: 'AUCTION',
  bid_strategy: 'LOWEST_COST_WITHOUT_CAP', // LOWEST_COST_WITH_BID_CAP - https://developers.facebook.com/docs/marketing-api/bidding/overview#opt
  budget: 10,
  spend_cap: 0,
  budgetWindow: 'DAILY'
}

export const callToActions = [
  {
    key: 'Open Link',
    value: 'OPEN_LINK',
    picture: ['OUTCOME_SALES'],
    videos: ['OUTCOME_SALES']
  },
  {
    key: 'Shop Now',
    value: 'SHOP_NOW',
    picture: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Play Game',
    value: 'PLAY_GAME',
    picture: ['OUTCOME_SALES', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Learn More',
    value: 'LEARN_MORE',
    picture: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Sign Up',
    value: 'SIGN_UP',
    picture: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Download',
    value: 'DOWNLOAD',
    picture: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Watch More',
    value: 'WATCH_MORE',
    picture: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Visit Page Feed',
    value: 'VISIT_PAGES_FEED',
    picture: [],
    videos: []
  },
  {
    key: 'Call Now',
    value: 'CALL_NOW',
    picture: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS'],
    videos: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS']
  },
  {
    key: 'Apply Now',
    value: 'APPLY_NOW',
    picture: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Get Offer',
    value: 'GET_OFFER',
    picture: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Buy Tickets',
    value: 'BUY_TICKETS',
    picture: ['OUTCOME_TRAFFIC'],
    videos: ['OUTCOME_TRAFFIC']
  },
  {
    key: 'Get Directions',
    value: 'GET_DIRECTIONS',
    picture: ['OUTCOME_SALES', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Subscribe',
    value: 'SUBSCRIBE',
    picture: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Donate Now',
    value: 'DONATE_NOW',
    picture: ['OUTCOME_SALES', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Get Quote',
    value: 'GET_QUOTE',
    picture: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Contact Us',
    value: 'CONTACT_US',
    picture: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Order Now',
    value: 'ORDER_NOW',
    picture: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Start Order',
    value: 'START_ORDER',
    picture: ['OUTCOME_SALES'],
    videos: ['OUTCOME_SALES']
  },
  {
    key: 'Request Time',
    value: 'REQUEST_TIME',
    picture: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Get Showtimes',
    value: 'GET_SHOWTIMES',
    picture: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Listen Now',
    value: 'LISTEN_NOW',
    picture: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Raise Money',
    value: 'RAISE_MONEY',
    picture: ['OUTCOME_SALES', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'WhatsApp Message',
    value: 'WHATSAPP_MESSAGE',
    picture: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_TRAFFIC', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  },
  {
    key: 'Find Your Groups',
    value: 'FIND_YOUR_GROUPS',
    picture: ['OUTCOME_SALES', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT'],
    videos: ['OUTCOME_SALES', 'OUTCOME_LEADS', 'OUTCOME_ENGAGEMENT']
  }
  //   LIKE_PAGE: {
  //     key: 'Like Page',
  //     picture: [],
  //     videos: []
  //   },
  //   INSTALL_APP: {
  //     key: "Install App",
  //     picture: [],
  //     videos: [],
  //   },
  //   USE_APP: {
  //     key: "Use App",
  //     picture: [],
  //     videos: [],
  //   },
  //   CALL_ME: {
  //     key: "Call Me",
  //     picture: [],
  //     videos: [],
  //   },
  //   VIDEO_CALL: {
  //     key: "Video Call",
  //     picture: [],
  //     videos: [],
  //   },
  //   INSTALL_MOBILE_APP: {
  //     key: "Install Mobile App",
  //     picture: [],
  //     videos: [],
  //   },
  //   USE_MOBILE_APP: {
  //     key: "Use Mobile App",
  //     picture: [],
  //     videos: [],
  //   },
  //   MOBILE_DOWNLOAD: {
  //     key: "Mobile Downloads",
  //     picture: [],
  //     videos: [],
  //   },
  //   CALL: {
  //     key: "Call Now",
  //     picture: ["sales", "traffic", "leads"],
  //     videos: ["sales", "traffic", "leads"],
  //   },
  //   BOOK_TRAVEL: {
  //     key: "Book Travel",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   LISTEN_MUSIC: {
  //     key: "Listen Music",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   WATCH_VIDEO: {
  //     key: "Watch More",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   NO_BUTTON: {
  //     key: "No Button",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   CONTACT: {
  //     key: "Contact",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   BUY_NOW: {
  //     key: "Buy Now",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   GET_OFFER_VIEW: {
  //     key: "Get Offer View",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   UPDATE_APP: {
  //     key: "Update App",
  //     picture: [],
  //     videos: [],
  //   },
  //   BUY: {
  //     key: "Buy",
  //     picture: [],
  //     videos: [],
  //   },
  //   SEND_UPDATES: {
  //     key: "Send Updates",
  //     picture: [],
  //     videos: [],
  //   },
  //   MESSAGE_PAGE: { send message?
  //     key: "Message Page",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   DONATE: {
  //     key: "Donate",
  //     picture: ["sales", "leads", "engagement"],
  //     videos: ["sales", "leads", "engagement"],
  //   },
  //   SAY_THANKS: {
  //     key: "Say Thanks",
  //     picture: [],
  //     videos: [],
  //   },
  //   SELL_NOW: {
  //     key: "Sell Now",
  //     picture: [],
  //     videos: [],
  //   },
  //   SHARE: {
  //     key: "Share",
  //     picture: [],
  //     videos: [],
  //   },
  //   ADD_TO_CART: {
  //     key: "Add To Cart",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   VIDEO_ANNOTATION: {
  //     key: "Video Annotation",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   MOMENTS: {
  //     key: "Moments",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   RECORD_NOW: {
  //     key: "Record Now",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   REFER_FRIENDS: {
  //     key: "Refer Friends",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   WOODHENGE_SUPPORT: {
  //     key: "Woodhenge Support",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   SOTTO_SUBSCRIBE: {
  //     key: "Sotto Subscribe",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   FOLLOW_USER: {
  //     key: "Follow User",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   EVENT_RSVP: { view event?
  //     key: "Event RSVP",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   FOLLOW_NEWS_STORYLINE: {
  //     key: "Follow News Storyline",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   SEE_MORE: {
  //     key: "See More",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   FIND_A_GROUP: {
  //     key: "Find A Group",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   PAY_TO_ACCESS: {
  //     key: "Pay To Access",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   PURCHASE_GIFT_CARDS: {
  //     key: "Purchase Gift Cards",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   FOLLOW_PAGE: {
  //     key: "Follow Page",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   SEND_A_GIFT: {
  //     key: "Send A Gift",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   SWIPE_UP_SHOP: {
  //     key: "Swipe Up Shop",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   SWIPE_UP_PRODUCT: {
  //     key: "Swipe Up Product",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   SEND_GIFT_MONEY: {
  //     key: "Send Gift Money",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   PLAY_GAME_ON_FACEBOOK: {
  //     key: "Play Game On Facebook",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   GET_STARTED: {
  //     key: "Get Started",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   OPEN_INSTANT_APP: {
  //     key: "Open Instant App",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
  //   AUDIO_CALL: {
  //     key: "Audio Call",
  //     picture: ["sales", "traffic", "leads", "engagement"],
  //     videos: ["sales", "traffic", "leads", "engagement"],
  //   },
]

/*
TODO: missing actions: check availability, try in camera, Book now, see menu, send message, get access,
      remind me, preregister, vote now, interested, view event
*/

// const images = {
//   sales: [
//     'Call Now',
//     'Learn more',
//     'Shop now',
//     'Sign up',
//     'Download',
//     'Apply now',
//     'Raise money',
//     'Play game',
//     'Subscribe',
//     'Check Availability',
//     'Find Your Groups',
//     'Get Tickets',
//     'Open link',
//     'Try in Camera',
//     'Order now',
//     'Get quote',
//     'Listen now',
//     'Donate now',
//     'Book now',
//     'Request time',
//     'See menu',
//     'Start order',
//     'Send message',
//     'Get directions',
//     'Send WhatsApp message',
//     'Watch more',
//     'Get offer',
//     'Contact us',
//     'Get showtimes'
//   ],
//   traffic: [
//     'Shop now',
//     'Book now',
//     'Learn more',
//     'Sign up',
//     'Download',
//     'Apply now',
//     'Order now',
//     'Call Now',
//     'Subscribe',
//     'Check Availability',
//     'Get Access',
//     'Get Tickets',
//     'Try in Camera',
//     'Remind Me',
//     'Buy tickets',
//     'Send WhatsApp message',
//     'Request time',
//     'Contact us',
//     'Get quote',
//     'Listen now',
//     'Watch more',
//     'See menu',
//     'Get offer',
//     'Get showtimes',
//     'Send message'
//   ],
//   leads: [
//     'Call Now',
//     'Check Availability',
//     'Download',
//     'Find Your Groups',
//     'Get Tickets',
//     'Apply now',
//     'Learn more',
//     'Play game',
//     'Preregister',
//     'Raise money',
//     'Shop now',
//     'Sign up',
//     'Subscribe',
//     'Get directions',
//     'Send WhatsApp message',
//     'Book now',
//     'Send message',
//     'Watch more',
//     'Donate now',
//     'Vote now',
//     'See menu',
//     'Listen now',
//     'Get quote',
//     'Get offer',
//     'Order now',
//     'Request time',
//     'Contact us',
//     'Get showtimes'
//   ],
//   engagement: [
//     'Learn more',
//     'Shop now',
//     'Book now',
//     'Download',
//     'Subscribe',
//     'Interested',
//     'Find Your Groups',
//     'Apply now',
//     'Raise money',
//     'Play game',
//     'Check Availability',
//     'Get Tickets',
//     'Try in Camera',
//     'Send WhatsApp message',
//     'View event',
//     'Donate now',
//     'Request time',
//     'See menu',
//     'Listen now',
//     'Contact us',
//     'Sign up',
//     'Order now',
//     'Watch more',
//     'Get offer',
//     'Get directions',
//     'Get quote',
//     'Get showtimes',
//     'Send message'
//   ]
// }

// const videos = {
//   sales: [
//     'Call Now',
//     'Learn more',
//     'Shop now',
//     'Download',
//     'Apply now',
//     'Raise money',
//     'Play game',
//     'Subscribe',
//     'Check Availability',
//     'Find Your Groups',
//     'Get Tickets',
//     'Open link',
//     'Try in Camera',
//     'Send message',
//     'Donate now',
//     'Send WhatsApp message',
//     'Request time',
//     'Get quote',
//     'Order now',
//     'Listen now',
//     'Start order',
//     'Watch more',
//     'See menu',
//     'Get directions',
//     'Get offer',
//     'Get showtimes',
//     'Contact us',
//     'Book now',
//     'Sign up'
//   ],
//   traffic: [
//     'Shop now',
//     'Learn more',
//     'Sign up',
//     'Download',
//     'Apply now',
//     'Call Now',
//     'Subscribe',
//     'Check Availability',
//     'Get Access',
//     'Get Tickets',
//     'Try in Camera',
//     'Remind Me',
//     'Order now',
//     'Watch more',
//     'Book now',
//     'Get offer',
//     'Get quote',
//     'Listen now',
//     'Buy tickets',
//     'Send message',
//     'See menu',
//     'Send WhatsApp message',
//     'Get showtimes',
//     'Request time',
//     'Contact us'
//   ],
//   leads: [
//     'Call Now',
//     'Check Availability',
//     'Download',
//     'Find Your Groups',
//     'Get Tickets',
//     'Apply now',
//     'Learn more',
//     'Play game',
//     'Preregister',
//     'Raise money',
//     'Shop now',
//     'Sign up',
//     'Subscribe',
//     'Vote now',
//     'Send message',
//     'Donate now',
//     'Get quote',
//     'Send WhatsApp message',
//     'Book now',
//     'Listen now',
//     'Watch more',
//     'Order now',
//     'See menu',
//     'Get offer',
//     'Get showtimes',
//     'Get directions',
//     'Contact us',
//     'Request time'
//   ],
//   engagement: [
//     'Learn more',
//     'Shop now',
//     'Sign up',
//     'Download',
//     'Subscribe',
//     'Interested',
//     'Find Your Groups',
//     'Apply now',
//     'Raise money',
//     'Play game',
//     'Check Availability',
//     'Get Tickets',
//     'Try in Camera',
//     'View event',
//     'Donate now',
//     'Request time',
//     'Send WhatsApp message',
//     'Get quote',
//     'Contact us',
//     'Get showtimes',
//     'Order now',
//     'Book now',
//     'Listen now',
//     'Watch more',
//     'See menu',
//     'Get offer',
//     'Get directions',
//     'Send message'
//   ]
// }
