import RootState from '@/rootstate'
import { cloneDeep } from 'lodash'
import { ActionTree } from 'vuex'
import State from './state'

export const actions: ActionTree<State, RootState> = {
  async getProducts({ commit, state }) {
    const { data } = await this.state.apiClient.getProducts(state.params)
    commit('setProducts', data)
  },

  async addProductVideo({ commit, state }, data) {
    await this.state.apiClient.updateProductVideo(data.productId, data.src)
    const products = cloneDeep(state.products)
    for (let i = 0; i < products.length; i++) {
      if (products[i]._id === data.productId) {
        products[i].videos.push(data.src)
      }
    }
    commit('setProducts', products)
  },

  async addProductPicture({ commit, state }, data) {
    await this.state.apiClient.updateProductPicture(data.productId, data.src)
    const products = cloneDeep(state.products)
    for (let i = 0; i < products.length; i++) {
      if (products[i]._id === data.productId) {
        products[i].pictures.push(data.src)
      }
    }
    commit('setProducts', products)
  },

  setParams({ commit }, params) {
    commit('setParams', params)
  }
}
