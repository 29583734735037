import { Module } from 'vuex'
import RootState from '@/rootstate'
import State from './state'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'

const state: State = {
  automations: [],
  params: {
    page: 1,
    serverItemsLength: 0,
    itemsPerPage: 20,
    tab: 0
  }
}

export const automation: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
