
import { Component, Prop } from 'vue-property-decorator'
import BusinessList from '@/modules/account/components/BusinessList.vue'
import VueStrong from '@/VueStrong'

@Component({ components: { BusinessList } })
export default class SideMenu extends VueStrong {
  @Prop({ default: false }) open!: boolean

  // this is a fix due to an issue with the toggling of the sidebar
  get drawerStyle() {
    return { width: this.open ? '256px' : '0px' }
  }

  get logoMargin() {
    return this.open ? '16px' : '16px 12px 12px 12px'
  }
}
