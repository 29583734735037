import { Module } from 'vuex'
import RootState from '@/rootstate'
import State from './state'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'

const state: State = {
  accounts: [],
  adAccount: null,
  user: null
}

export const account: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
