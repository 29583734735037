import VueRouter, { RouteConfig } from 'vue-router'

import Cookies from 'js-cookie'
import FacebookAuth from '@/views/FacebookAuth.vue'
import Home from '@/views/Home.vue'
import Vue from 'vue'
import store from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    alias: '/login',
    name: 'Home',
    component: Home
  },
  {
    path: '/fb/callback',
    name: 'FacebookCallback',
    component: FacebookAuth,
    meta: { requiresAuth: true }
  },
  {
    path: '/app',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: { requiresAuth: true }
  },

  {
    path: '/templates',
    name: 'Templates',
    component: () => import(/* webpackChunkName: "templates" */ '../views/Templates.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/campaign',
    name: 'CampaignFlow',
    component: () => import(/* webpackChunkName: "campaign-flow" */ '../views/CampaignFlow.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/insights',
    name: 'Insights',
    component: () => import(/* webpackChunkName: "insights" */ '../views/Insights.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/product-insights',
    name: 'ProductInsights',
    component: () => import(/* webpackChunkName: "product-insights" */ '../views/ProductInsights.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/overview-insights',
    name: 'OverviewInsights',
    component: () => import(/* webpackChunkName: "overview-insights" */ '../views/OverviewInsights.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/businesses',
    name: 'Businesses',
    component: () => import(/* webpackChunkName: "businesses" */ '../views/Business.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/automations/list',
    name: 'AutomationsList',
    component: () => import(/* webpackChunkName: "automations-list" */ '@/modules/automation/views/Automations.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/automations/details/:id',
    name: 'Automation',
    meta: { requiresAuth: true, guestAccess: true },
    component: () => import(/* webpackChunkName: "automation" */ '@/modules/automation/views/AutomationDetail.vue'),
    props: true
  },
  {
    path: '/automations/:id',
    name: 'AutomationsUpsert',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "automations-upsert" */ '@/modules/automation/views/Upsert.vue')
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const { meta } = to
  const anyCanAccess = to.path.includes('/automations/details/') || (meta && meta.guestAcces)
  const requiresAuth = meta && meta.requiresAuth
  const hasRefreshToken = Cookies.get('refreshToken')
  const hasUserData: boolean = store.getters.hasUserData
  let isAuthenticated: boolean = store.getters.isAuthenticated

  try {
    if (hasRefreshToken && !isAuthenticated) {
      const res = await store.dispatch('refreshToken')
      if (!res) throw new Error('failed to refresh')
      isAuthenticated = true
    }

    if (!hasUserData && isAuthenticated) {
      await store.dispatch('account/getBusinesses')
      await store.dispatch('account/getUser')
    }

    if (anyCanAccess || (requiresAuth && isAuthenticated)) return next()
    if (!requiresAuth && isAuthenticated) return next({ name: 'Dashboard' })
    if (requiresAuth && !isAuthenticated) return next({ name: 'Home' })
    next()
  } catch (error) {
    console.log(error)
    if (anyCanAccess) return next()
    next({ name: 'Home' })
  }
})

export default router
