/* eslint-disable no-console, no-undef */

import { register } from 'register-service-worker'

if (!process.env.VUE_APP_IS_TEST) {
  register(`/service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered(reg) {
      console.log('Service worker has been registered.')
      setInterval(() => {
        reg.update()
      }, 1000 * 60 * 30) // check if we have update every 30 mins
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('Update found! New content is downloading.')
    },
    updated(reg) {
      console.log('New content is available; please refresh.')
      document.dispatchEvent(new CustomEvent('swUpdated', { detail: reg }))
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
