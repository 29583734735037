import { GetterTree } from 'vuex'
import RootState from '@/rootstate'
import State from './state'

export const getters: GetterTree<State, RootState> = {
  totalAdSetsCurrentVariations(state): number {
    const totalAgeVariations = state.generalSetting.adSet.variations.ageVariations.length
    const totalPlacementVariations = state.generalSetting.adSet.variations.placementVariations.length
    const totalAudienceVariations = state.generalSetting.adSet.variations.audienceVariations.length

    return totalAgeVariations * totalAudienceVariations * totalPlacementVariations
  },

  isMaxAdSetVariations(state, getters): boolean {
    const totalAdSets = getters.totalAdSetsCurrentVariations
    const adSetVariations = 100
    return totalAdSets >= adSetVariations
  },
  totalAdCreativesCurrentVariations(state): number {
    const totalGeneralVariations = state.generalSetting?.adCreative?.generalVariations.length
    const totalUrlTagsVariations = state.generalSetting?.adCreative?.urlTagsVariations.length

    return totalGeneralVariations * totalUrlTagsVariations
  },
  isMaxAdCreativeVariations(state, getters): boolean {
    const totalAdCreatives = getters.totalAdCreativesCurrentVariations
    const adCreativeVariations = state.adCreativeVariations
    if (totalAdCreatives >= adCreativeVariations) {
      return true
    } else {
      return false
    }
  }
}
