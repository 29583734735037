import State, { ISetting } from './state'

import { formatText } from '@/helpers/utils'
import RootState from '@/rootstate'
import { cloneDeep } from 'lodash'
import { ActionTree } from 'vuex'

export const actions: ActionTree<State, RootState> = {
  setShop({ commit }, shop: yakkyo.IShop) {
    commit('setShop', shop)
  },

  selectProduct({ commit }, product) {
    commit('addSelectedProduct', product)
  },

  setSelectedPictures({ commit }, pictures) {
    commit('setSelectedPictures', pictures)
  },

  setSelectedVideos({ commit }, videos) {
    commit('setSelectedVideos', videos)
  },

  setSelectedAdPictures({ commit }, pictures) {
    commit('setAdSelectedPictures', pictures)
  },

  setSelectedAdVideos({ commit }, videos) {
    commit('setAdSelectedVideos', videos)
  },

  setSelectedProducts({ commit }, selectedProducts) {
    commit('setSelectedProducts', selectedProducts)
  },

  async addProductVideo({ commit, state, dispatch }, data) {
    await dispatch('product/addProductVideo', data, { root: true })
    const selectedProducts = cloneDeep(state.selectedProducts)
    for (let i = 0; i < selectedProducts.length; i++) {
      if (selectedProducts[i]._id === data.productId) {
        selectedProducts[i].videos.push(data.src)
      }
    }
    commit('setSelectedProducts', selectedProducts)
  },

  async addProductPicture({ commit, state, dispatch }, data) {
    await dispatch('product/addProductPicture', data, { root: true })
    const selectedProducts = cloneDeep(state.selectedProducts)
    for (let i = 0; i < selectedProducts.length; i++) {
      if (selectedProducts[i]._id === data.productId) {
        selectedProducts[i].pictures.push(data.src)
      }
    }
    commit('setSelectedProducts', selectedProducts)
  },

  unselectProduct({ commit }, product) {
    commit('removeSelectedProduct', product)
  },

  updateSettings({ commit }, settings) {
    commit('updateSettings', settings)
  },

  updateGeneralSetting({ commit }, settings) {
    commit('updateGeneralSetting', settings)
  },

  async publish({ commit, state, dispatch }, shopifyProducts) {
    const settings: ISetting[] = cloneDeep(state.settings)
    for (const setting of settings) {
      if (setting.importStatus === 1) continue

      try {
        const shopifyProduct = shopifyProducts.find(p => p.ykProduct === setting.product._id)
        const productPictures = state.selectedPictures.filter(p => p.product === setting.product._id)
        const productVideos = state.selectedVideos.filter(p => p.product === setting.product._id)

        const adCreatives = setting.adCreative.generalVariations.flatMap(general => {
          const link = general.link
            ? formatText(general.link, shopifyProduct)
            : formatText('{{ shopify_link }}', shopifyProduct)
          const title = formatText(general.title, shopifyProduct)
          const description = formatText(general.description, shopifyProduct)
          const body = formatText(general.body, shopifyProduct)
          const urlTags = setting.adCreative.urlTagsVariations.map(urlTag => new URLSearchParams(urlTag).toString())
          return urlTags.map(urlTag => ({ link, title, description, body, urlTags: urlTag }))
        })
        const campaign = {
          ...setting,
          adAccount: this.state.account.adAccount,
          assets: productPictures,
          videos: productVideos,
          fbPictures: state.selectedAdImages,
          fbVideos: state.selectedAdVideos,
          adCreative: adCreatives
        }

        await this.state.apiClient.publishCampaign(campaign)
        setting.importStatus = 1
        setting.importError = ''
      } catch (error) {
        console.log(error)
        setting.importStatus = -1
        setting.importError =
          error && error.response && error.response.data && error.response.data.error
            ? error.response.data.error
            : 'Something went wrong'
      }

      commit('updateSettings', settings)
    }

    if (state.templateName) {
      try {
        const template = {
          name: state.templateName,
          ...state.generalSetting, // TODO: fix mapping
          generalVariations: state.generalSetting.adCreative.generalVariations,
          urlTagsVariations: state.generalSetting.adCreative.urlTagsVariations
        }
        dispatch('template/createTemplate', { type: 'campaign', template }, { root: true })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
