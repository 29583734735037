import { MutationTree } from 'vuex'
import State from './state'
import { cloneDeep } from 'lodash'

export const mutations: MutationTree<State> = {
  setAutomations(state, data) {
    state.automations = cloneDeep(data.automations || [])
    state.params.serverItemsLength = data.total || 0
    state.params.itemsPerPage = data.itemsPerPage || 10
    state.params.page = data.page || 1
  },
  setParams(state, params) {
    state.params = params
  }
}
