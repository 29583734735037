import { defaultAdCreative, defaultAdSet, defaultCampaign } from '@/helpers/data'
import RootState from '@/rootstate'
import { ActionTree } from 'vuex'
import State, { ITemplate, SnackbarParams } from './state'

export const actions: ActionTree<State, RootState> = {
  async getTemplates({ commit, state }, type = 'campaign') {
    const { data } = await this.state.apiClient.getTemplates(state.params, type)
    if (type === 'campaign') {
      const templates = data.templates
        .map(d => {
          if (d.campaign && d.adSet && d.adCreative) return d
        })
        .filter(t => !!t)
      data.templates = templates
    }
    commit('setTemplates', { data, type })
  },

  setParams({ commit }, { params, type }) {
    commit('setParams', { params, type })
  },

  setType({ commit }, type) {
    commit('setType', type)
  },

  setSnackbar({ commit }, params: SnackbarParams = null) {
    commit('setSnackbar', params)
  },

  setSelectedTemplate({ commit }, template: ITemplate = null) {
    commit('setSelectedTemplate', template)
  },

  newTemplate({ commit, state }) {
    if (state.type === 'adcreative') commit('setSelectedTemplate', defaultAdCreative)
    else if (state.type === 'adset') commit('setSelectedTemplate', defaultAdSet)
    else if (state.type === 'campaign') commit('setSelectedTemplate', { name: '', campaign: defaultCampaign })
  },

  async createTemplate({ dispatch, commit }, { template, type }) {
    commit('setLoading', true)
    await this.state.apiClient.postTemplate(template, type)
    dispatch('getTemplates', type)
    commit('setLoading', false)
  },

  async updateTemplate({ dispatch, commit }, { id, template, type }) {
    commit('setLoading', true)
    await this.state.apiClient.patchTemplate(id, template, type)
    dispatch('getTemplates', type)
    commit('setLoading', false)
  },

  async deleteTemplate({ dispatch, commit }, { id, type }) {
    commit('setLoading', true)
    await this.state.apiClient.removeTemplate(id, type)
    dispatch('getTemplates', type)
    commit('setLoading', false)
  }
}
