
import { Component } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'

@Component
export default class Home extends VueStrong {
  private password = ''
  private email = ''
  show = false
  snackbar = false

  mainDashboardBaseUrl = process.env.VUE_APP_YAKKYOFY_DASHBOARD || 'https://app.yakkyofy.com/'

  rules = {
    required: (value: string): boolean | string => !!value || 'Required.',
    min: (v: string): boolean | string => v.length >= 4 || 'Min 4 characters'
  }

  get loginDisabled(): boolean {
    const noPassword = !this.password || !this.password.length || this.password.length < 4
    const noUser = !this.email || !this.email.length || this.email.length < 8
    return noPassword || noUser
  }

  async login(e): Promise<void> {
    e.preventDefault()
    if (this.loginDisabled) return

    try {
      await this.$store.dispatch('login', { password: this.password, email: this.email })
      const url = new URLSearchParams(window.location.search).get('redirect') || { name: 'Dashboard' }
      this.$router.push(url)
    } catch (error) {
      console.log('error?', error)
      this.snackbar = true
    }
  }
}
