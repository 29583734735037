import { Module } from 'vuex'
import RootState from '@/rootstate'
import State from './state'
import { actions } from './actions'
import { mutations } from './mutations'

const state: State = {
  products: [],
  params: {
    page: 1,
    serverItemsLength: 0,
    itemsPerPage: 12,
    sku: '',
    productVisibility: 'private'
  }
}

export const product: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
}
