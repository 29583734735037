import { MutationTree } from 'vuex'
import State from './state'
import { cloneDeep } from 'lodash'
import smartlookClient from 'smartlook-client'

export const mutations: MutationTree<State> = {
  setBusinesses(state, accounts) {
    state.accounts = cloneDeep(accounts || [])
  },
  setAdAccount(state, adAccount) {
    state.adAccount = cloneDeep(adAccount)
    localStorage.setItem('adAccount', JSON.stringify(adAccount))
  },
  setUser(state, user) {
    state.user = cloneDeep(user)
    console.log('smartlookClient.initialized()', smartlookClient.initialized())
    if (smartlookClient.initialized() && state.user) {
      smartlookClient.identify(state.user._id, {
        name: state.user.fullName,
        email: state.user.email
      })
    }
  }
}
