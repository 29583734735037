import { MutationTree } from 'vuex'
import State from './state'

export const mutations: MutationTree<State> = {
  setCampaigns(state, data) {
    state.campaigns = data.campaigns || []
    state.campaignParams.serverItemsLength = data.total || 0
    state.campaignParams.itemsPerPage = data.itemsPerPage || 12
    state.campaignParams.page = data.page || 1
  },
  setAdSets(state, data) {
    state.adSets = data.adSets || []
    state.adSetParams.serverItemsLength = data.total || 0
    state.adSetParams.itemsPerPage = data.itemsPerPage || 12
    state.adSetParams.page = data.page || 1
  },
  setAds(state, data) {
    state.ads = data.ads || []
    state.adParams.serverItemsLength = data.total || 0
    state.adParams.itemsPerPage = data.itemsPerPage || 12
    state.adParams.page = data.page || 1
  },
  setProductInsights(state, data) {
    state.products = data.products || []
    state.productParams.serverItemsLength = data.total || 0
    state.productParams.itemsPerPage = data.itemsPerPage || 12
    state.productParams.page = data.page || 1
  },
  setInsightsOverview(state, data) {
    state.overviewData = data
  },
  setOverviewParams(state, params) {
    state.overviewParams = params
  },
  setCampaignParams(state, params) {
    state.campaignParams = params
  },
  setAdSetParams(state, params) {
    state.adSetParams = params
  },
  setAdParams(state, params) {
    state.adParams = params
  },
  setProductParams(state, params) {
    state.productParams = params
  },
  setSelectedCampaignId(state, id) {
    state.selectedCampaignId = id
  },
  setSelectedAdSetId(state, id) {
    state.selectedAdSetId = id
  },
  setAdCampaignAdsets(state, payload) {
    const { adSets, ads, campaign } = payload
    state.AdCampaignAdsets.campaign = campaign
    state.AdCampaignAdsets.adSets = adSets
    state.AdCampaignAdsets.ads = ads
  },
  setCampaing(state, payload) {
    state.AdCampaignAdsets.campaign = payload
  },
  setAdVariation(state, payload) {
    state.AdCampaignAdsets.ads = payload
  },
  setAdSetVariation(state, payload) {
    state.AdCampaignAdsets.adSets = payload
  },
  setSelectedAccount(state, payload) {
    state.AdCampaignAdsets.selectedAccout = payload
  },
  resetAdCampaignAdsets(state) {
    state.AdCampaignAdsets.campaign = {}
    state.AdCampaignAdsets.adSets = []
    state.AdCampaignAdsets.ads = []
    state.AdCampaignAdsets.selectedAccout = {}
  }
}
