import { MutationTree } from 'vuex'
import State from './state'
import _ from 'lodash'

export const mutations: MutationTree<State> = {
  setProducts(state, data) {
    state.products = data.products || []
    state.params.serverItemsLength = data.total || 0
    state.params.itemsPerPage = data.itemsPerPage || 12
    state.params.page = data.page || 1
  },
  setParams(state, params) {
    state.params = _.cloneDeep(params)
  }
}
