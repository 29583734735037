import RootState from '@/rootstate'
import moment from 'moment'
import { Module } from 'vuex'
import { actions } from './actions'
import { mutations } from './mutations'
import State from './state'

const state: State = {
  selectedAdSetId: '',
  selectedCampaignId: '',
  campaigns: [],
  adSets: [],
  ads: [],
  products: [],
  overviewData: [],
  overviewParams: {
    groupBy: 'week',
    start: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD')
  },
  campaignParams: {
    page: 1,
    serverItemsLength: 0,
    itemsPerPage: 12,
    start: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    campaignName: '',
    campaignId: '',
    effectiveStatus: ''
  },
  adSetParams: {
    page: 1,
    serverItemsLength: 0,
    itemsPerPage: 12,
    start: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    adSetName: '',
    adSetId: '',
    effectiveStatus: ''
  },
  adParams: {
    page: 1,
    serverItemsLength: 0,
    itemsPerPage: 12,
    start: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    adName: '',
    adId: '',
    effectiveStatus: ''
  },
  productParams: {
    page: 1,
    serverItemsLength: 0,
    itemsPerPage: 12,
    start: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD')
  },
  AdCampaignAdsets: {
    campaign: {},
    adSets: [],
    ads: [],
    selectedAccout: {},
    adAccountId: ''
  }
}

export const insight: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
}
