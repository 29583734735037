
import { Component } from 'vue-property-decorator'
import SideMenu from '@/components/SideMenu.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import BusinessList from '@/modules/account/components/BusinessList.vue'
import BusinessSelect from '@/modules/account/components/BusinessSelect.vue'
import UpdateAvailable from '@/components/UpdateAvailable.vue'
import VueStrong from '@/VueStrong'
import smartlookClient from 'smartlook-client'
import { debounce } from 'lodash'

@Component({ components: { SideMenu, BusinessList, BusinessSelect, UserAvatar, UpdateAvailable } })
export default class App extends VueStrong {
  drawer = false
  hasAcceptedTOS = false
  loading = false

  debounceAcceptTOS = debounce(this.acceptTOS, 1000)

  get acceptedTOS() {
    return this.isAuthenticated && this.user && this.user.pandaRocketTOS
  }

  get user() {
    return this.$store.state.account.user
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly
  }

  get isAuthenticated() {
    return this.$store.getters.isAuthenticated
  }

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount || '' : ''
  }

  get isAuthenticating() {
    return this.$route.name === 'FacebookCallback'
  }

  get isLoggingout() {
    return this.$route.query && !!this.$route.query.logout
  }

  get isManagingBusinesses() {
    return this.$route.name === 'Businesses'
  }

  mounted() {
    // if (!process.env.VUE_APP_SMARTLOOK_TOKEN) return
    smartlookClient.init('cd506e3da01da4df0b6b45ddeed8d63ee27cb901')
  }

  async acceptTOS() {
    this.loading = true
    try {
      await this.$store.dispatch('acceptTOS')
    } catch (error) {
      console.log(error)
    }
    this.loading = false
  }

  logout() {
    try {
      this.$store.dispatch('logout')
      this.$router.push({ name: 'Home', query: { logout: 'true' } })
    } catch (error) {
      console.log(error)
    }
  }
}
