
import VueStrong from '@/VueStrong'
import { Component } from 'vue-property-decorator'

@Component
export default class UpdateAvailable extends VueStrong {
  refreshing = false
  registration: any = null
  updateExists = false

  created() {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  }

  updateAvailable(event: any) {
    this.registration = event.detail
    this.updateExists = true
  }

  refreshApp() {
    this.updateExists = false
    if (!this.registration || !this.registration.waiting) return
    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
  }
}
