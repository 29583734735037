
import { Component } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'

@Component
export default class BusinessSelect extends VueStrong {
  get accounts(): yakkyo.IFacebookAccount[] {
    return this.$store.state.account ? this.$store.state.account.accounts || [] : []
  }

  get selectedAdAccount() {
    return this.$store.state.account?.adAccount
  }

  get businesses(): yakkyo.IFacebookBusiness[] {
    return this.$store.state.account?.accounts.reduce((acc, account) => acc.concat(account.businesses), [])
  }

  get selectedBusiness() {
    if (!this.selectedAdAccount) return null
    return this.businesses.find(business =>
      business.adAccounts.find(adAccount => adAccount.id === this.selectedAdAccount.id)
    )
  }

  selectAdAccount(adAccount: yakkyo.IFacebookAdAccount) {
    this.$store.commit('account/setAdAccount', adAccount)
  }

  redirectToBusinessList() {
    this.$router.push({ name: 'Businesses' })
  }
}
