import State, { audienceVariation, ISetting } from './state'

import { cloneDeep } from 'lodash'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<State> = {
  setShop(state, shop) {
    state.shop = cloneDeep(shop)
  },
  addSelectedProduct(state, product) {
    state.selectedProducts = cloneDeep([...state.selectedProducts, product])
  },
  removeSelectedProduct(state, product) {
    state.selectedProducts = cloneDeep(state.selectedProducts.filter(p => p._id !== product._id))
    state.selectedPictures = cloneDeep(state.selectedPictures.filter(p => p.product !== product._id))
    state.settings = cloneDeep(state.settings.filter(s => s.product._id !== product._id))
  },
  setSelectedPictures(state, selectedPictures) {
    state.selectedPictures = cloneDeep(selectedPictures)
  },
  setSelectedVideos(state, selectedVideos) {
    state.selectedVideos = cloneDeep(selectedVideos)
  },
  setAdSelectedPictures(state, selectedPictures) {
    state.selectedAdImages = cloneDeep(selectedPictures)
  },
  setAdSelectedVideos(state, selectedVideos) {
    state.selectedAdVideos = cloneDeep(selectedVideos)
  },
  setSelectedProducts(state, selectedProducts) {
    state.selectedProducts = cloneDeep(selectedProducts)
  },
  updateSettings(state, settings: ISetting[]) {
    state.settings = cloneDeep(settings)
  },
  updateGeneralSetting(state, generalSetting: ISetting) {
    state.generalSetting = cloneDeep(generalSetting)
  },
  setTemplateName(state, templateName) {
    state.templateName = templateName
  },
  setStep(state, step) {
    state.step = step
  },
  setValidStep(state, step) {
    const steps = state.steps
    steps[step.type].submitted = step.value.submitted
    steps[step.type].valid = step.value.valid
    if (step.applySettingsFor) {
      state.applySettingsFor = step.applySettingsFor
    }
    state.steps = cloneDeep(steps)
  },
  setApplySettingsFor(state, value) {
    state.applySettingsFor = value
  },
  setPreSelectedProducts(state, product) {
    state.preSelectedProduct = product
  },
  setValidationTrigger(state, value) {
    state.validationTrigger = value
  },
  setChangeStepError(state, error: 'products' | 'campaign' | 'adSet' | 'adCreative' | 'chooseMedia' | '') {
    state.changeStepError = error
  },
  setShowTemplates(state, showTemplates) {
    state.showTemplates = showTemplates
  },
  setAdSetVariations(state, adSetVariations) {
    state.adSetVariations = adSetVariations

    // get current variations
    const audience = state.generalSetting.adSet.variations.audienceVariations.length
    const age = state.generalSetting.adSet.variations.ageVariations.length
    const placement = state.generalSetting.adSet.variations.placementVariations.length
    const totalVariations = audience * age * placement

    console.log('total:', totalVariations)

    const variation = state.generalSetting.adSet.variations.audienceVariations[0]
    if (!variation) return

    const newVariations: [] & audienceVariation[] = [] as any
    for (let i = 0; i < adSetVariations; i++) newVariations.push(cloneDeep(variation))

    state.generalSetting.adSet.variations.audienceVariations = newVariations
  },
  setAdCreativeVariations(state, adCreativeVariations) {
    state.adCreativeVariations = adCreativeVariations

    // get current variations

    const variation = state.generalSetting.adCreative.generalVariations[0]
    if (!variation) return

    const newVariations: Partial<yakkyo.IFacebookAdTemplateAdCreative>[] = []
    for (let i = 0; i < adCreativeVariations; i++) newVariations.push(cloneDeep(variation))

    state.generalSetting.adCreative.generalVariations = newVariations
  },
  updateVariations(state, template) {
    // get current variations
    const audience = template.adSet.variations.audienceVariations.length
    const age = template.adSet.variations.ageVariations.length
    const placement = template.adSet.variations.placementVariations.length
    const totalVariationsAdset = audience * age * placement

    state.adSetVariations = totalVariationsAdset
    state.adCreativeVariations =
      template.adCreative.generalVariations.length * template.adCreative.urlTagsVariations.length
  }
}
