import { defaultAdCreative, defaultAdSet, defaultCampaign } from '@/helpers/data'

import { Module } from 'vuex'
import RootState from '@/rootstate'
import State from './state'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'

const state: State = {
  step: 1,
  steps: {
    products: { submitted: false, valid: true },
    adSet: { submitted: false, valid: true },
    campaign: { submitted: false, valid: true },
    adCreative: { submitted: false, valid: true },
    chooseMedia: { submitted: false, valid: true }
  },
  applySettingsFor: { entity: 'Every product', productId: '' },
  validationTrigger: 'stepChange',
  changeStepError: '',
  showTemplates: false,
  selectedProducts: [],
  selectedPictures: [],
  selectedVideos: [],
  selectedAdImages: [],
  selectedAdVideos: [],
  shop: null,
  settings: [],
  templateName: '',
  preSelectedProduct: [],
  // TODO: create utility
  generalSetting: {
    page: '',
    campaign: {
      ...defaultCampaign
    },
    adCreative: {
      ...defaultAdCreative,
      generalVariations: [
        {
          name: '', // just the template name
          status: 'ACTIVE',
          link: '',
          title: '',
          description: '',
          body: '',
          callToAction: ''
        }
      ],
      urlTagsVariations: [
        {
          utm_medium: '{{placement}}',
          utm_campaign: '{{campaing.name}}',
          utm_source: 'cpc',
          utm_term: '{{ad.id}}',
          utm_content: '{{ad.name}}'
        }
      ]
    },
    adSet: {
      ...defaultAdSet,
      start_time: '',
      end_time: '',
      variations: {
        audienceVariations: [
          {
            locales: [],
            interests: [],
            geo_locations: ['IT'],
            selectedTargetInterests: [],
            selectedTargetLocales: []
          }
        ],
        ageVariations: [
          {
            genders: {
              male: true,
              female: true
            },
            age_max: 65,
            age_min: 13
          }
        ],
        placementVariations: [
          {
            manual: false,
            audience_network_positions: [
              { name: 'Classic', value: 'classic', selected: true, kind: 'audience_network_positions' },
              { name: 'Rewarded Video', value: 'rewarded_video', selected: true, kind: 'audience_network_positions' }
            ],
            device_platforms: [
              { name: 'Desktop', value: 'desktop', kind: 'device_platforms', selected: true },
              { name: 'Mobile', value: 'mobile', kind: 'device_platforms', selected: true }
            ],
            facebook_positions: [
              { name: 'Feed', value: 'feed', kind: 'facebook_positions', selected: true },
              { name: 'Right Hand Column', value: 'right_hand_column', kind: 'facebook_positions', selected: true },
              { name: 'Marketplace', value: 'marketplace', kind: 'facebook_positions', selected: true },
              { name: 'Video Feeds', value: 'video_feeds', kind: 'facebook_positions', selected: true },
              { name: 'Story', value: 'story', kind: 'facebook_positions', selected: true },
              { name: 'Search', value: 'search', kind: 'facebook_positions', selected: true },
              { name: 'Instream Video', value: 'instream_video', kind: 'facebook_positions', selected: true },
              { name: 'Facebook Reels', value: 'facebook_reels', kind: 'facebook_positions', selected: true }
            ],
            instagram_positions: [
              { name: 'Stream', value: 'stream', kind: 'instagram_positions', selected: true },
              { name: 'Story', value: 'story', kind: 'instagram_positions', selected: true },
              { name: 'Shop', value: 'shop', kind: 'instagram_positions', selected: true },
              { name: 'Explore', value: 'explore', kind: 'instagram_positions', selected: true },
              { name: 'Explore Home', value: 'explore_home', kind: 'instagram_positions', selected: true },
              { name: 'Reels', value: 'reels', kind: 'instagram_positions', selected: true },
              { name: 'Profile Feed', value: 'profile_feed', kind: 'instagram_positions', selected: true }
            ],
            messenger_positions: [
              { name: 'Messenger Home', value: 'messenger_home', kind: 'messenger_positions', selected: true },
              { name: 'Sponsored Messages', value: 'sponsored_messages', kind: 'messenger_positions', selected: true },
              { name: 'Story', value: 'story', kind: 'messenger_positions', selected: true }
            ],
            publisher_platforms: [
              { name: 'Facebook', value: 'facebook', kind: 'publisher_platforms', selected: true },
              { name: 'Instagram', value: 'instagram', kind: 'publisher_platforms', selected: true },
              { name: 'Audience Network', value: 'audience_network', kind: 'publisher_platforms', selected: true },
              { name: 'Messenger', value: 'messenger', kind: 'publisher_platforms', selected: true }
            ]
          }
        ]
      } as any
    }
  },
  adSetVariationLimit: 200,
  adSetVariations: 1,
  adCreativeVariations: 1
}

export const launch: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
