import { render, staticRenderFns } from "./UpdateAvailable.vue?vue&type=template&id=3c7f8f2d&scoped=true&"
import script from "./UpdateAvailable.vue?vue&type=script&lang=ts&"
export * from "./UpdateAvailable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c7f8f2d",
  null
  
)

export default component.exports