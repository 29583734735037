import { GetterTree } from 'vuex'
import RootState from '@/rootstate'
import State from './state'

export const getters: GetterTree<State, RootState> = {
  pages(state): any[] {
    if (!state.adAccount) return []

    let pages = []
    for (const account of state.accounts) {
      for (const business of account.businesses) {
        if (business.adAccounts.some(a => a.id === state.adAccount.id)) pages = business.pages || []
      }
    }

    return pages || []
  },

  instagramAccounts(state): any[] {
    if (!state.adAccount) return []

    let instagramAccounts = []
    for (const account of state.accounts) {
      for (const business of account.businesses) {
        if (business.adAccounts.some(a => a.id === state.adAccount.id))
          instagramAccounts = business.instagramAccounts || []
      }
    }

    return instagramAccounts || []
  },

  pixels(state): any[] {
    if (!state.adAccount) return []

    let pixels = []
    for (const account of state.accounts) {
      for (const business of account.businesses) {
        if (business.adAccounts.some(a => a.id === state.adAccount.id)) pixels = business.pixels || []
      }
    }

    return pixels
  }
}
