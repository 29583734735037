import RootState from '@/rootstate'
import router from '@/router'
import { ActionTree } from 'vuex'
import State from './state'

export const actions: ActionTree<State, RootState> = {
  setAdAccount({ commit }, adAccount: yakkyo.IFacebookAdAccount) {
    commit('setAdAccount', adAccount)
  },

  async getBusinesses({ commit }) {
    const { data } = await this.state.apiClient.getBusinesses()
    const adAccount = localStorage.getItem('adAccount') ? JSON.parse(localStorage.getItem('adAccount')) : null
    if (adAccount && data.some(d => d.businesses.some(b => !!b.adAccounts.find(a => a.id === adAccount.id))))
      commit('setAdAccount', adAccount)
    else if (adAccount) localStorage.removeItem('adAccount')

    commit('setBusinesses', data)
  },

  async updateAccounts({ commit, state }, payload) {
    const { item, businessId, type, adAccountId } = payload
    const params = { item, type, businessId }
    const { data } = await this.state.apiClient.updateBusiness(adAccountId, params)
    const { account } = data
    const accounts = state.accounts
    const toModifyIndex = accounts.findIndex(item => item._id === account._id)
    accounts[toModifyIndex] = account
    commit('setBusinesses', accounts)
  },

  async facebookCallback({ commit }, query: any) {
    await this.state.apiClient.facebookCallback(query)
    const { data } = await this.state.apiClient.getBusinesses()
    commit('setBusinesses', data)
    router.push({ name: 'Dashboard' })
  },

  async getUser() {
    const { data } = await this.state.apiClient.getUser()
    this.commit('account/setUser', data)
  }
}
