import { MutationTree } from 'vuex'
import State, { SnackbarParams, TemplateParams, ITemplate } from './state'

export const mutations: MutationTree<State> = {
  setTemplates(state: State, { data, type }) {
    state.templates[type] = data.templates || []

    state.params[type].serverItemsLength = data.total || 0
    state.params[type].itemsPerPage = data.itemsPerPage || 12
    state.params[type].page = data.page || 1
  },
  setParams(state: State, { params, type }: { params: TemplateParams; type: string }) {
    state.params[type] = params
  },
  setType(state: State, type) {
    state.type = type
  },
  setSnackbar(state: State, snackbar: SnackbarParams | null) {
    state.snackbar = snackbar
  },
  setSelectedTemplate(state: State, template: ITemplate) {
    state.selectedTemplate = template
  },
  setLoading(state: State, loading = false) {
    state.loading = loading
  }
}
