import { Module } from 'vuex'
import RootState from '@/rootstate'
import State from './state'
import { actions } from './actions'
import { mutations } from './mutations'

const defaultParams = {
  page: 1,
  serverItemsLength: 0,
  itemsPerPage: 12
}
const state: State = {
  type: 'campaign',
  loading: false,
  snackbar: null,
  selectedTemplate: null,
  templates: {
    campaign: [],
    adset: [],
    adcreative: []
  },
  params: {
    campaign: { ...defaultParams },
    adset: { ...defaultParams },
    adcreative: { ...defaultParams }
  }
}

export const template: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
}
