import { ActionTree } from 'vuex'
import RootState from '@/rootstate'
import State from './state'

export const actions: ActionTree<State, RootState> = {
  async list({ commit, state, rootState }) {
    const adAccountId = rootState.account.adAccount?.id
    if (!adAccountId) return commit('setAutomations', { automations: [], total: 0 })

    const { data } = await this.state.apiClient.listAutomations(state.params, adAccountId)
    commit('setAutomations', data)
  },

  async post({ dispatch, rootState }, automation: Partial<yakkyo.IFacebookAutomation>) {
    const { adAccount, accounts } = rootState.account
    if (!adAccount) return

    // TODO: craete getter in account module
    const facebookAccount = accounts.find(a => a.businesses.some(b => b.adAccounts.some(aa => aa.id === adAccount.id)))
    if (!facebookAccount) return

    automation.adAccountId = adAccount.id
    automation.facebookAccount = facebookAccount._id

    await this.state.apiClient.postAutomation(automation)
    await dispatch('list')
  },

  async update({ dispatch }, { id, automation }) {
    await this.state.apiClient.updateAutomation(id, automation)
    dispatch('list')
  },

  async duplicate({ dispatch, rootState }, automationId: string) {
    const adAccountId = rootState.account.adAccount?.id
    const { data } = await this.state.apiClient.duplicateAutomation(automationId, adAccountId)
    return data
  },

  async remove({ dispatch }, automationId: string) {
    await this.state.apiClient.removeAutomation(automationId)
    await dispatch('list')
  },

  async setParams({ commit, dispatch }, params: any) {
    commit('setParams', params)
  }
}
