
import { Component } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'

@Component
export default class UserAvatar extends VueStrong {
  yakkyofyDashboardBaseUrl = process.env.VUE_APP_YAKKYOFY_DASHBOARD || 'https://app.yakkyofy.com/'

  get user() {
    return this.$store.state.account.user
  }

  openLink(
    goTo: 'manageProfile' | 'manageStores' | 'manageInvoices' | 'subscriptions' | 'affiliateProgram' | 'dealsHub'
  ) {
    let link = this.yakkyofyDashboardBaseUrl

    switch (goTo) {
      case 'manageProfile':
        link += 'account/profile'
        break
      case 'manageStores':
        link += 'stores'
        break
      case 'manageInvoices':
        link += 'invoices'
        break
      case 'subscriptions':
        link += 'subscription'
        break
      case 'affiliateProgram':
        link += 'affiliate'
        break
      case 'dealsHub':
        link = 'https://yakkyofy.com/partners-bonus-perks-page-dropship-deals-hub/'
    }

    window.open(link, '_blank')
  }

  logout() {
    try {
      this.$store.dispatch('logout')
      this.$router.push({ name: 'Home', query: { logout: 'true' } })
    } catch (error) {
      console.log(error)
    }
  }
}
