
import { Component } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'

@Component
export default class FacebookAuth extends VueStrong {
  snackbar = false
  error = false

  async created() {
    try {
      await this.$store.dispatch('account/facebookCallback', this.$route.query)
    } catch (error) {
      console.log(error)
      this.snackbar = true
      this.error = true
    }
  }
}
