import RootState from '@/rootstate'
import { ActionTree } from 'vuex'
import State from './state'

export const actions: ActionTree<State, RootState> = {
  async getCampaigns({ commit, state }) {
    const { data } = await this.state.apiClient.getCampaigns(this.state.account.adAccount.id, state.campaignParams)
    commit('setCampaigns', data)
  },
  async getAdSets({ commit, state }) {
    const { data } = await this.state.apiClient.getAdSets(state.selectedCampaignId, state.adSetParams)
    commit('setAdSets', data)
  },
  async getAds({ commit, state }) {
    const { data } = await this.state.apiClient.getAds(state.selectedAdSetId, state.adParams)
    commit('setAds', data)
  },
  async getCampaign({ state }, id: string) {
    const { data } = await this.state.apiClient.getCampaign(this.state.account.adAccount.id, id)
    return data
  },
  async getProductInsights({ commit, state }) {
    const { data } = await this.state.apiClient.getProductInsights(state.productParams)
    commit('setProductInsights', data)
  },
  async getOverviewInsights({ commit, state }) {
    const { data } = await this.state.apiClient.getOverviewInsights(state.overviewParams)
    commit('setInsightsOverview', data)
  },
  async updateCampaignInsights({ commit, state }) {
    return new Promise<any>((resolve, reject) => {
      this.state.apiClient
        .updateCampaignInsights(this.state.account.adAccount.id)
        .then((response: any) => resolve(response))
        .catch((e: Error) => reject(e))
    })
  },
  async updateAdSetInsights({ commit, state }, campaignId: string) {
    return new Promise<any>((resolve, reject) => {
      this.state.apiClient
        .updateAdSetInsights(this.state.account.adAccount.id, campaignId)
        .then((response: any) => resolve(response))
        .catch((e: Error) => reject(e))
    })
  },
  async updateAdInsights({ commit, state }, adSetId: string) {
    return new Promise<any>((resolve, reject) => {
      this.state.apiClient
        .updateAdInsights(this.state.account.adAccount.id, adSetId)
        .then((response: any) => resolve(response))
        .catch((e: Error) => reject(e))
    })
  },
  copyCampaign({ state }, payload: any) {
    return this.state.apiClient.copyCampaign(payload)
  },
  copyAdSet({ state }, adSetId: string) {
    return this.state.apiClient.copyAdSet(adSetId)
  },
  copyAd({ state }, adId: string) {
    return this.state.apiClient.copyAd(adId)
  },
  async getAdCampaignAdsets({ commit }, payload: { campaign: string; adAccount: string }) {
    const { campaign, adAccount } = payload
    const { data } = await this.state.apiClient.getAdCampaignAdsets(campaign, adAccount)
    commit('setAdCampaignAdsets', data.data)
  },
  async copyCampaignToAnotherAccount({ commit }, adCampaignAdsets: any) {
    const { data } = await this.state.apiClient.copyCampaignToAnotherAccount(adCampaignAdsets)
    commit('resetAdCampaignAdsets')
    return data
  },
  setSelectedCampaignId({ commit }, campaign: yakkyo.IFacebookCampaign | null) {
    commit('setSelectedCampaignId', campaign ? campaign.campaignId : '')
  },
  setSelectedAdSetId({ commit }, adSet: yakkyo.IFacebookAdSet | null) {
    commit('setSelectedAdSetId', adSet ? adSet.adSetId : '')
  },
  setCampaignParams({ commit }, params) {
    commit('setCampaignParams', params)
  },
  setAdSetParams({ commit }, params) {
    commit('setAdSetParams', params)
  },
  setAdParams({ commit }, params) {
    commit('setAdParams', params)
  },
  setOverviewParams({ commit }, params) {
    commit('setOverviewParams', params)
  },
  setProductParams({ commit }, params) {
    commit('setProductParams', params)
  }
}
