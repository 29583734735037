import { iVariations } from '@/modules/launch/store/state'

export const formatText = (text: string, shopifyProduct: any) => {
  if (!shopifyProduct)
    return text
      .replace('{{ product_title }}', '')
      .replace('{{ product_subtitle }}', '')
      .replace('{{ bulletpoints }}', '')
      .replace('{{ link }}', '')
      .replace('{{ fb_link }}', '')
      .replace('{{ shopify_link }}', '')
      .replace('{{ price }}', '')
      .replace('{{ compare_at_price }}', '')
      .replace('{{ discount_value }}', '')
      .replace('{{ discount_percentage }}', '')

  return text
    .replace('{{ product_title }}', shopifyProduct.title)
    .replace('{{ product_subtitle }}', shopifyProduct.subtitle)
    .replace('{{ bulletpoints }}', shopifyProduct.bulletpoints)
    .replace('{{ link }}', shopifyProduct.storefrontUrl + '/product/' + shopifyProduct.handle)
    .replace('{{ fb_link }}', shopifyProduct.storefrontUrl + '/fb/product/' + shopifyProduct.handle)
    .replace('{{ shopify_link }}', shopifyProduct.shopifyDomain + '/products/' + shopifyProduct.handle)
    .replace('{{ price }}', shopifyProduct.variants[0].price)
    .replace('{{ compare_at_price }}', shopifyProduct.variants[0].compare_at_price || shopifyProduct.variants[0].price)
    .replace(
      '{{ discount_value }}',
      `${
        parseFloat(shopifyProduct.variants[0].compare_at_price || shopifyProduct.variants[0].price) -
        parseFloat(shopifyProduct.variants[0].price)
      }`
    )
    .replace(
      '{{ discount_percentage }}',
      `${Math.ceil(
        (100 *
          (parseFloat(shopifyProduct.variants[0].compare_at_price || shopifyProduct.variants[0].price) -
            parseFloat(shopifyProduct.variants[0].price))) /
          parseFloat(shopifyProduct.variants[0].compare_at_price || shopifyProduct.variants[0].price)
      )}`
    )
}

export const combineAdsetVariations = (variations: iVariations) => {
  const ageVariations: any = variations?.ageVariations || []
  const placementVariations: any = variations?.placementVariations || []
  const audienceVariations: any = variations?.audienceVariations || []

  // Return an array of all possible variations combined
  return audienceVariations.flatMap(audienceVariation =>
    ageVariations.flatMap(ageVariation => {
      return placementVariations.map(placementVariation => {
        const { geo_locations, locales, interests } = audienceVariation

        return {
          geo_locations: { countries: geo_locations },
          locales,
          interests: interests.map(({ id, name, audience_size_lower_bound, audience_size_upper_bound }) => ({
            id,
            name,
            audience_size_lower_bound,
            audience_size_upper_bound
          })),
          age_max: ageVariation.age_max,
          age_min: ageVariation.age_min,
          genders: ageVariation.genders,
          placements: {
            manual: placementVariation.manual,
            device_platforms: placementVariation.device_platforms
              .filter(({ selected }) => selected)
              .map(({ value }) => value),
            publisher_platforms: placementVariation.publisher_platforms
              .filter(({ selected }) => selected)
              .map(({ value }) => value),
            facebook_positions: placementVariation.facebook_positions
              .filter(({ selected }) => selected)
              .map(({ value }) => value),
            instagram_positions: placementVariation.instagram_positions
              .filter(({ selected }) => selected)
              .map(({ value }) => value),
            audience_network_positions: placementVariation.audience_network_positions
              .filter(({ selected }) => selected)
              .map(({ value }) => value),
            messenger_positions: placementVariation.messenger_positions
              .filter(({ selected }) => selected)
              .map(({ value }) => value)
          }
        }
      })
    })
  )
}
