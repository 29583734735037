
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'

@Component
export default class BusinessList extends VueStrong {
  @Prop({ default: false }) asComponent!: boolean

  get accounts(): yakkyo.IFacebookAccount[] {
    return this.$store.state.account ? this.$store.state.account.accounts || [] : []
  }

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount || '' : ''
  }

  selectAdAccount(adAccount: yakkyo.IFacebookAdAccount) {
    this.$store.commit('account/setAdAccount', adAccount)
  }

  redirectToBusinessList() {
    this.$router.push({ name: 'Businesses' })
  }
}
