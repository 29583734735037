import ApiClient from './api-client'
import Cookies from 'js-cookie'
import RootState from './rootstate'
import Vue from 'vue'
import Vuex from 'vuex'
import { account } from '@/modules/account/store/module'
import { automation } from '@/modules/automation/store/module'
import { cloneDeep } from 'lodash'
import { insight } from '@/modules/insight/store/module'
import { launch } from '@/modules/launch/store/module'
import { product } from '@/modules/product/store/module'
import smartlookClient from 'smartlook-client'
import { template } from '@/modules/template/store/module'

Vue.use(Vuex)

const { VUE_APP_API, VUE_APP_BASE_API, VUE_APP_COOKIE_DOMAIN } = process.env

const apiClient = new ApiClient(
  VUE_APP_API || 'https://facebook-ads-backend.crm.yakkyofy.com',
  VUE_APP_BASE_API || 'https://api.yakkyofy.com'
)

const initialState: RootState = {
  apiClient,
  token: Cookies.get('token') || '',
  refreshToken: Cookies.get('refreshToken') || ''
}

export default new Vuex.Store({
  state: cloneDeep(initialState),
  modules: cloneDeep({ account, automation, template, launch, insight, product }),
  mutations: {
    login(state, tokens) {
      state.token = tokens.token
      state.refreshToken = tokens.refreshToken

      const cookieData = {
        ...(tokens.rememberMe ? { expires: 365 } : {}),
        ...(VUE_APP_COOKIE_DOMAIN ? { domain: VUE_APP_COOKIE_DOMAIN } : {})
      }
      Cookies.set('token', tokens.token, cookieData)
      Cookies.set('refreshToken', tokens.refreshToken, cookieData)
      Cookies.set('issuedDate', Date.now(), cookieData)
    },
    logout(state) {
      Object.assign(state, { ...cloneDeep(initialState), token: '', refreshToken: '' })
    }
  },
  getters: {
    isAuthenticated: state => {
      const token = state.token
      const issuedDate = Cookies.get('issuedDate')
      if (!issuedDate) return false

      const expirationDate = parseInt(issuedDate) + 3600 * 1000 * 24
      if (Date.now() >= expirationDate || !token) return false
      return true
    },
    hasUserData: state => {
      return !!state.account.user
    }
  },

  actions: {
    login({ state }, authData: any) {
      return new Promise((resolve, reject) => {
        state.apiClient
          .login(authData)
          .then(response => {
            // TODO: remove once out of beta
            const unauthorized =
              response.data.user.privileges < 2 && !(response.data.user.additionalScopes || '').includes('marketing')

            if (unauthorized) {
              reject(new Error('Unauthorized.'))
              return
            }

            this.commit('login', {
              token: response.data.token,
              refreshToken: response.data.refreshToken,
              rememberMe: authData.rememberMe
            })

            this.dispatch('account/getUser')
            this.dispatch('account/getBusinesses')
            resolve(true)
          })
          .catch(error => {
            console.log(error)
            console.log('failed to refresh')
            this.dispatch('logout')
            reject(error)
          })
      })
    },

    acceptTOS({ state }) {
      return new Promise((resolve, reject) => {
        state.apiClient
          .acceptTOS()
          .then(response => {
            this.dispatch('account/getUser')
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            console.log('failed to accept tos')
            reject(error)
          })
      })
    },

    refreshToken({ state }) {
      return new Promise((resolve, reject) => {
        state.apiClient
          .refresh()
          .then(response => {
            const unauthorized =
              response.data.user.privileges < 2 && !(response.data.user.additionalScopes || '').includes('marketing')
            if (unauthorized) return reject(new Error('Unauthorized.'))

            this.commit('login', { token: response.data.token, refreshToken: response.data.refreshToken })
            this.commit('account/setUser', response.data)
            this.dispatch('account/getBusinesses')

            resolve(response)
          })
          .catch(error => {
            console.log('failed to refresh')
            this.dispatch('logout')
            reject(error)
          })
      })
    },

    logout() {
      Cookies.remove('token')
      Cookies.remove('refreshToken')
      Cookies.remove('issuedDate')
      if (smartlookClient.initialized()) smartlookClient.anonymize()

      localStorage.removeItem('adAccount')
      this.commit('account/setUser', null)
      this.commit('logout')
    }
  }
})
